import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDKuj14H6Au1mkAMigslglUr_CIJE6MGgA",
  authDomain: "otp-web-dde01.firebaseapp.com",
  projectId: "otp-web-dde01",
  storageBucket: "otp-web-dde01.appspot.com",
  messagingSenderId: "808219740681",
  appId: "1:808219740681:web:c0cf9e1daac0eb93fbc6d8",
  measurementId: "G-ZV1C4FCNK2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
